import React, { useCallback } from 'react';
import CopyIcon from 'ui/Icons/copy.component.svg';
import { enqueueNotification } from 'store/modules/ui';
import { BookingBuilder } from 'services/BackendApi';
import { useDispatch, useSelector } from 'react-redux';
import * as HotelAccommodationProductSelectors from 'store/modules/hotelAccommodationProducts/selectors';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import { IHAPFormattedAccommodationProduct } from 'store/modules/hotelAccommodationProducts/types';
import { SvgIcon } from 'ui/SvgIcon';
import { getBookingConfirmationClipboardFormat } from './CopyButtonMultiple';

interface ICopyButtonProps {
  booking: BookingBuilder;
}

export const CopyButton: React.FC<ICopyButtonProps> = React.memo(({ booking }) => {
  const dispatch = useDispatch();
  const paymentTerms = useSelector(BookingBuilderSelectors.bookingPaymentTermsSelector);
  const formattedAccommodationProducts: IHAPFormattedAccommodationProduct[] = useSelector(
    HotelAccommodationProductSelectors.getHotelAccommodationProductsSelector
  );
  const handleCopy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(
        getBookingConfirmationClipboardFormat(booking, paymentTerms, formattedAccommodationProducts, { isFull: true})
      );
      dispatch(
        enqueueNotification({
          message: `Copied booking information to clipboard`,
          options: { variant: 'success' },
        })
      );
    } catch (e) {
      console.log('e', e);
      dispatch(
        enqueueNotification({
          message: `Failed to copy booking information to clipboard`,
          options: { variant: 'error' },
        })
      );
    }
  }, [booking, dispatch, paymentTerms]);

  return (
    <button
      className="bg-transparent border-none outline-none cursor-pointer hover:bg-gray-20 rounded-full w-6 h-6 flex items-center justify-around"
      onClick={handleCopy}
    >
      <SvgIcon IconComponent={CopyIcon} width="18px" height="18px" className="-ml-[2px] fill-brown-prime" />
    </button>
  );
});
